import { reactive, ref } from 'vue'
import { UserRepository } from '@/repositories/UserRepository'
import type { ElForm } from 'element-plus'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'

export type FormInstance = InstanceType<typeof ElForm>

export const useUserEditDialog = () => {
  const formRef = ref<FormInstance>();

  const form = reactive<{id: number|null, values: {name: string, password: string, role: string}}>({
    id: null,
    values: {
      name: '',
      password: '',
      role: 'member',
    }
  });

  const rules = reactive({
    password: [
      {
        min: 6,
        message: 'パスワードは6文字以上で入力してください',
      }
    ],
    name: [
      {
        required: true,
        message: '名前を入力してください',
        trigger: 'blur',
      }
    ],
    role: [
      {
        required: true,
        message: '権限を入力してください',
        trigger: 'blur',
      }
    ],
  });

  const loading = ref(false);

  const handleSubmit = async () => {
    if (form.id === null) {
      return;
    }

    loading.value = true;

    try {
      const repository = new UserRepository();
      await repository.edit(form.id, form.values);
      loading.value = false;
    } catch (e: any) {
      loading.value = false;
      useExceptionMessage(e);

      return false;
    }

    return true;
  };

  return {
    formRef,
    handleSubmit,
    loading,
    form,
    rules,
  };
};

export const useUserEditDialogView = () => {
  const visible = ref(false);
  const editUser = reactive({id: 0, name: '', role: ''});

  const handleClick = (values: any) => {
    editUser.id = values.id;
    editUser.name = values.name;
    editUser.role = values.role;

    visible.value = true;
  };

  return {
    visible,
    editUser,
    handleClick,
  }
}
