import { reactive, ref } from 'vue'
import { UserRepository } from '@/repositories/UserRepository'
import type { ElForm } from 'element-plus'
import { useExceptionMessage } from '@/logic/ExceptionMessageLogic'

export interface UserValues {
  login_id: string;
  password: string;
  name: string;
}

export type FormInstance = InstanceType<typeof ElForm>

export const useUserAddDialog = () => {
  const formRef = ref<FormInstance>();

  const form = reactive({
    values: {
      login_id: '',
      password: '',
      name: '',
      role: 'member',
    }
  });

  const rules = reactive({
    login_id: [
      {
        required: true,
        message: 'ログインIDを入力してください',
        trigger: 'blur',
      },
      {
        pattern: /^[0-9a-zA-Z]+$/,
        message: 'ログインIDは英数字で入力してください',
        trigger: 'change',
      },
    ],
    password: [
      {
        required: true,
        message: 'パスワードを入力してください',
        trigger: 'blur',
      },
      {
        min: 6,
        message: 'パスワードは6文字以上で入力してください',
      }
    ],
    name: [
      {
        required: true,
        message: '名前を入力してください',
        trigger: 'blur',
      }
    ],
    role: [
      {
        required: true,
        message: '権限を入力してください',
        trigger: 'blur',
      }
    ],
  });

  const loading = ref(false);

  const handleSubmit = async () => {
    loading.value = true;

    try {
      const repository = new UserRepository();
      await repository.add(form.values);
    } catch (e: any) {
      loading.value = false;
      useExceptionMessage(e);

      return false;
    }

    loading.value = false;

    return true;
  };

  return {
    formRef,
    handleSubmit,
    loading,
    form,
    rules,
  };
};

export const useUserAddDialogView = () => {
  const visible = ref(false);

  const handleClick = (values: any) => {
    visible.value = true;
  };

  return {
    visible,
    handleClick,
  }
};
