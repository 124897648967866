import { reactive, ref } from 'vue'
import { UserRepository } from '@/repositories/UserRepository'

export const useUserSuspendDialog = () => {
  const form = reactive<{id: number|null}>({
    id: null,
  });
  const loading = ref(false);

  const handleSubmit = async () => {
    if (form.id === null) {
      return;
    }

    loading.value = true;

    try {
      const repository = new UserRepository();
      await repository.suspend(form.id);
    } catch (e) {
      // TODO
    }

    loading.value = false;
  };

  return {
    handleSubmit,
    loading,
    form,
  };
};

export const useUserSuspendDialogView = () => {
  const visible = ref(false);
  const suspendUser = reactive({id: 0});

  const handleClick = (values: any) => {
    suspendUser.id = values.id;

    visible.value = true;
  };

  return {
    visible,
    suspendUser,
    handleClick,
  }
}
